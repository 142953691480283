@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.cards-bg {
    background-position: 0px 125px;
    background-repeat: no-repeat;
    background-image: linear-gradient(135deg, rgba(0,40,90,1) 0%, rgba(100,125,155,1) 67%);
    min-height: 500px
}

.cards-continue-btn {
    background: radial-gradient(circle at 50% 100%, #B4BECD 50%, transparent 51%)
}

@include media-breakpoint-down(xl) {
    .card {
        width: 80%;
    }
}

@include media-breakpoint-down(lg) {
    .card {
        width: 85%;
    }
}

@include media-breakpoint-down(md) {
    .card {
        width: 75%;
    }
}

@include media-breakpoint-down(sm) {
    .map {
        height: 450px;
    }
}

@include media-breakpoint-up(sm) {
    .map {
        height: 600px;
    }
}
